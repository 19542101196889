<template>
  <v-dialog
    v-if="selectedMenuItem"
    v-model="isVisible"
    v-resize="onResize"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="800"
    persistent
    scrollable
  >
    <v-card>
      <template v-if="!selectedMenuItem.Image || !UseMenuLargeImage">
        <v-toolbar
          :height="toolbarHeight"
          :style="'min-height:'+ toolbarHeight + 'px'"
          card
          class="menu-image"
          color="gfp-adv-common-product-dialog-toolbar-background"
          light
          prominent
        >
          <div
            v-if="selectedMenuItem.Image && !UseMenuLargeImage && $vuetify.breakpoint.smAndUp"
            style="width: 180px; height: 100px;"
          >
            <img
              v-lazy="selectedMenuItem.Image"
              style="width: 180px; height: 100px; border-radius: 5px; object-fit: cover;"
            >
          </div>

          <v-toolbar-title ref="toolbarTitle">
            <div class="title gfp-adv-common-product-dialog-toolbar-title--text">
              <template v-if="selectedMenuItem.Lang">
                <strong>{{ selectedMenuItem.Lang[$i18n.locale].Name }}</strong>
              </template>
            </div>

            <div class="title pt-2 gfp-adv-common-product-dialog-toolbar-price--text">
              <strong
                v-if="selectedMenuItem.Quantity > 1"
                class="gfp-adv-common-product-dialog-toolbar-quantity--text"
              >
                {{ selectedMenuItem.Quantity }} x
              </strong>
              <template v-if="selectedMenuItem.SpecialStatus && selectedMenuItem.IsSpecial">
                <span class="old-price">
                  {{ selectedMenuItem.PriceFmt | currency }}
                </span> {{ selectedMenuItem.SpecialPriceFmt | currency }}
              </template>
              <template v-else>
                <template v-if="selectedMenuItem.UnitId > 1">
                  {{ getMenuUnitBasePriceWithOptions(selectedMenuItem) | currency }}
                  <span
                    class="grey--text text-caption"
                  >/ {{ getMeasurementUnitById(selectedMenuItem.UnitId).BaseUnit }}</span>
                </template>
                <template v-else>
                  {{ selectedMenuItem.PriceFmt | currency }}
                </template>
              </template>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            :color="getContrastColor($vuetify.theme['gfp-adv-common-product-dialog-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />
      </template>

      <v-card-text
        ref="dialogOptionsContent"
        :style="isCatalogOnly ? selectedMenuItem.Options && selectedMenuItem.Options.length <= 0 && selectedMenuItem.Image ? 'padding-top: 0; padding-bottom:0;' : '' : ''"
        style="min-height: 200px;"
        class="fill-height gfp-adv-common-product-dialog-body-background pa-0"
      >
        <v-btn
          v-if="selectedMenuItem.Image && UseMenuLargeImage && !toolbarVisible"
          absolute
          class="safe-area-top dialog-cancel-button"
          dark
          fab
          right
          small
          style="top: 10px;"
          top
          @click="onCancelButtonClick"
        >
          <v-icon>close</v-icon>
        </v-btn>

        <div
          v-if="selectedMenuItem.Image && UseMenuLargeImage"
          :style="`position: absolute; z-index: 3; height: ${toolbarHeight}px; width: 100%;`"
        >
          <v-toolbar
            v-show="toolbarVisible"
            :height="toolbarHeight"
            :style="`min-height: ${toolbarHeight}px; height: ${toolbarHeight}px; width: 100%;`"
            :color="toolbarColor"
            class="menu-image"
            card
            light
            prominent
          >
            <div
              v-if="selectedMenuItem.Image && $vuetify.breakpoint.smAndUp"
              style="width: 180px; height: 100px;"
            >
              <img
                v-lazy="selectedMenuItem.Image"
                style="width: 180px; height: 100px; border-radius: 5px; object-fit: cover;"
              >
            </div>

            <v-toolbar-title ref="toolbarTitle">
              <div class="title gfp-adv-common-product-dialog-toolbar-title--text">
                <template v-if="selectedMenuItem.Lang">
                  <strong>{{ selectedMenuItem.Lang[$i18n.locale].Name }}</strong>
                </template>
              </div>

              <div class="title pt-2 gfp-adv-common-product-dialog-toolbar-price--text">
                <strong
                  v-if="selectedMenuItem.Quantity > 1"
                  class="gfp-adv-common-product-dialog-toolbar-quantity--text"
                >
                  {{ selectedMenuItem.Quantity }} x
                </strong>
                <template v-if="selectedMenuItem.SpecialStatus && selectedMenuItem.IsSpecial">
                  <span class="old-price">
                    {{ selectedMenuItem.PriceFmt | currency }}
                  </span> {{ selectedMenuItem.SpecialPriceFmt | currency }}
                </template>
                <template v-else>
                  <template v-if="selectedMenuItem.UnitId > 1">
                    {{ selectedMenuItem.OriginalUnitPrice | currency }}
                    <span
                      class="grey--text text-caption"
                    >/ {{ getMeasurementUnitById(selectedMenuItem.UnitId).BaseUnit }}</span>
                  </template>
                  <template v-else>
                    {{ selectedMenuItem.PriceFmt | currency }}
                  </template>
                </template>
              </div>
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              icon
              @click="onCancelButtonClick"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
        </div>

        <v-img
          v-if="selectedMenuItem.Image && UseMenuLargeImage"
          v-observe-visibility="{
            callback: (visible, entry) => visibilityChanged(visible, entry),
            throttle: 0,
            throttleOptions: {
              leading: 'visible',
            },
            intersection: {
              //root: ...,
              rootMargin: '0px 0px 0px 0px',
              threshold: 0.32,
            },
          }"
          :src="selectedMenuItem.Image"
          max-height="350"
        >
          <v-container
            fill-height
            fluid
          >
            <v-layout
              align-end
              row
              wrap
            >
              <v-flex xs12>
                <div
                  v-if="selectedMenuItem.Lang"
                  class="pa-2 d-inline-block align-end justify-end"
                  style="background-color: rgba(255,255,255,0.75); border-radius: 5px;"
                >
                  <div class="title gfp-adv-common-product-dialog-toolbar-title--text">
                    <template v-if="selectedMenuItem.Lang">
                      <strong>{{ selectedMenuItem.Lang[$i18n.locale].Name }}</strong>
                    </template>
                  </div>

                  <div class="title pt-2 gfp-adv-common-product-dialog-toolbar-price--text">
                    <strong
                      v-if="selectedMenuItem.Quantity > 1"
                      class="gfp-adv-common-product-dialog-toolbar-quantity--text"
                    >
                      {{ selectedMenuItem.Quantity }} x
                    </strong>

                    <template v-if="selectedMenuItem.SpecialStatus && selectedMenuItem.IsSpecial">
                      <span class="old-price">
                        {{ selectedMenuItem.PriceFmt | currency }}
                      </span> {{ selectedMenuItem.SpecialPriceFmt | currency }}
                    </template>
                    <template v-else>
                      <template v-if="selectedMenuItem.UnitId > 1">
                        {{ selectedMenuItem.OriginalUnitPrice | currency }}
                        <span
                          class="grey--text text-caption"
                        >/ {{ getMeasurementUnitById(selectedMenuItem.UnitId).BaseUnit }}</span>
                      </template>
                      <template v-else>
                        {{ selectedMenuItem.PriceFmt | currency }}
                      </template>
                    </template>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>

        <div class="ma-2">
          <div
            v-if="selectedMenuItem.Lang && (selectedMenuItem.Lang[$i18n.locale].Description || selectedMenuItem.Description)"
            class="subheading my-2 mx-1 gfp-adv-common-product-dialog-body-description--text"
            v-html="selectedMenuItem.Lang[$i18n.locale].Description || selectedMenuItem.Description"
          />

          <div class="my-2 mx-1">
            <template v-for="tag in getMenuItemTags(selectedMenuItem)">
              <v-tooltip
                v-if="tag.Visible && !tag.System"
                :key="`menu-tag-tooltip-${tag.Id}`"
                :color="tag.Color || '#666666'"
                bottom
              >
                <template #activator="{on}">
                  <v-chip
                    v-if="tag.Visible && !tag.System"
                    :key="`menu-tag-${tag.Id}`"
                    :color="tag.Color || '#666666'"
                    small
                    label
                    class="menu-tag"
                    :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`"
                    v-on="on"
                  >
                    <v-icon
                      v-if="tag.Icon && !tag.LabelOnly"
                      small
                    >
                      {{ tag.Icon }}
                    </v-icon>

                    <span
                      v-if="!tag.Icon || tag.LabelOnly"
                      class="caption"
                      :class="{'ml-1': !!tag.Icon && !tag.LabelOnly}"
                    >
                      {{ tag.Label }}
                    </span>
                  </v-chip>
                </template>

                <div :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`">
                  <div class="font-weight-bold text-xs-center">
                    {{ tag.Lang[$i18n.locale].Label }}
                  </div>
                  <div class="caption text-xs-left">
                    {{ tag.Lang[$i18n.locale].Description }}
                  </div>
                </div>
              </v-tooltip>
            </template>
          </div>

          <template v-if="selectedMenuItem.Options && selectedMenuItem.Options.length <= 0 && isCatalogOnly">
            <div
              v-if="!selectedMenuItem.Image"
              class="d-flex align-center justify-center"
            >
              <v-img
                :alt="appConfig.LOCATION_DATA.Name"
                :src="getContrastLogo(LOGO_TYPE.MAIN)"
                max-width="250"
                style="opacity: 0.25;"
              />
            </div>
          </template>

          <template v-else>
            <v-form>
              <v-container
                fluid
                grid-list-xs
                ma-0
                pa-0
              >
                <v-layout
                  row
                  wrap
                >
                  <template v-for="item in selectedMenuItem.Options">
                    <v-flex
                      v-if="selectedMenuItem.Lang && selectedMenuItem.Lang[$i18n.locale]"
                      v-show="item.Visible !== false"
                      :key="item.Id"
                      xs12
                    >
                      <v-flex
                        subheading
                        xs12
                      >
                        <template v-if="item.DisplayType === 'separator'">
                          <div
                            v-if="item.DisplayName"
                            class="text-xs-center separator-title font-weight-bold mt-2 pl-1 gfp-adv-common-product-dialog-body-options-title--text"
                          >
                            {{ item.Lang[$i18n.locale].Name }}
                          </div>
                          <v-divider v-else />
                        </template>

                        <div
                          v-else
                          class="font-weight-bold mt-2 pl-1 gfp-adv-common-product-dialog-body-options-title--text"
                        >
                          {{ item.Lang[$i18n.locale].Name }}
                        </div>

                        <v-alert
                          v-if="item.Error"
                          :value="true"
                          class="mx-2 pa-1 caption"
                          color="error"
                          icon="warning"
                          outline
                        >
                          {{ item.Error.Message }}
                        </v-alert>
                      </v-flex>

                      <v-flex xs12>
                        <v-container
                          fluid
                          grid-list-xs
                          ma-0
                          pa-0
                        >
                          <v-layout
                            row
                            wrap
                          >
                            <template v-if="item.DisplayType === 'checkbox'">
                              <template v-for="option in item.OptionValues">
                                <v-flex
                                  v-if="option.Lang && option.Lang[$i18n.locale]"
                                  v-show="option.Visible!==false"
                                  :key="item.Id + '-checkbox-' + option.Id"
                                  class="xs12 sm6"
                                >
                                  <v-card
                                    class="ma-1"
                                    style="cursor: pointer;"
                                    color="gfp-adv-common-product-dialog-body-option-background"
                                    flat
                                    @click="onCheckBoxClick(option)"
                                  >
                                    <v-card-text class="pa-2">
                                      <div class="option-value-row">
                                        <div class="option-value-row-control">
                                          <v-checkbox
                                            v-model="option.Selected"
                                            :disabled="(option.Mandatory && option.Selected) || option.Disabled"
                                            :required="option.Mandatory"
                                            readonly
                                            class="mt-0 pt-0"
                                            color="gfp-adv-common-product-dialog-body-option-control"
                                            hide-details
                                          >
                                            <template #label>
                                              <span
                                                :class="{'font-weight-bold': option.Selected}"
                                                class="gfp-adv-common-product-dialog-body-option-text--text"
                                                style="font-size: 14px; line-height: 17px;"
                                                v-html="option.Lang[$i18n.locale].Name"
                                              />
                                            </template>
                                          </v-checkbox>
                                        </div>

                                        <div class="option-value-row-price">
                                          <div
                                            v-if="option.ShowPrice"
                                            :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                            class="caption right pr-1 gfp-adv-common-product-dialog-body-option-text--text"
                                            style="margin-top: 2px;"
                                          >
                                            {{ option.PriceFmt | currency }}
                                          </div>
                                        </div>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-flex>
                              </template>
                            </template>

                            <v-flex
                              v-if="item.DisplayType === 'radio' || item.DisplayType === 'unit_value'"
                              xs12
                            >
                              <v-radio-group
                                v-model="item.DefaultValueId"
                                :mandatory="item.Required"
                                class="pa-0 ma-0"
                                hide-details
                                row
                                @change="onRadioChange"
                              >
                                <template v-for="option in item.OptionValues">
                                  <v-flex
                                    v-if="option.Lang && option.Lang[$i18n.locale]"
                                    v-show="option.Visible!==false"
                                    :key="item.Id + '-radio-' + option.Id"
                                    class="xs12 sm6"
                                  >
                                    <v-card
                                      class="ma-1"
                                      color="gfp-adv-common-product-dialog-body-option-background"
                                      style="cursor: pointer;"
                                      flat
                                      @click="onRadioClick(item, option)"
                                    >
                                      <v-card-text class="pa-2">
                                        <div class="option-value-row">
                                          <div class="option-value-row-control">
                                            <v-radio
                                              :value="option.Id"
                                              class="mt-0 pt-0"
                                              color="gfp-adv-common-product-dialog-body-option-control"
                                              hide-details
                                              placeholder=""
                                            >
                                              <span
                                                slot="label"
                                                :class="{'font-weight-bold': option.Selected}"
                                                class="gfp-adv-common-product-dialog-body-option-text--text"
                                                style="font-size: 14px; line-height: 17px;"
                                                v-html="option.Lang[$i18n.locale].Name"
                                              />
                                            </v-radio>
                                          </div>

                                          <div class="option-value-row-price">
                                            <span
                                              v-if="option.ShowPrice"
                                              :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                              class="caption right pt-2 pr-1 gfp-adv-common-product-dialog-body-option-text--text"
                                              style="min-height: 24px; line-height: 10px;"
                                            >
                                              {{ option.PriceFmt | currency }}
                                            </span>
                                          </div>
                                        </div>
                                      </v-card-text>
                                    </v-card>
                                  </v-flex>
                                </template>
                              </v-radio-group>
                            </v-flex>

                            <v-flex
                              v-if="item.DisplayType === 'unit'"
                              xs12
                            >
                              <v-radio-group
                                v-model="item.DefaultValueId"
                                :mandatory="item.Required"
                                class="pa-0 ma-0"
                                hide-details
                                row
                                @change="onRadioChange"
                              >
                                <template v-for="option in item.OptionValues">
                                  <v-flex
                                    v-if="option.Lang && option.Lang[$i18n.locale]"
                                    v-show="option.Visible!==false"
                                    :key="item.Id + '-unit-' + option.Id"
                                    class="xs12 sm6"
                                  >
                                    <v-card
                                      class="ma-1"
                                      color="gfp-adv-common-product-dialog-body-option-background"
                                      style="cursor: pointer;"
                                      flat
                                      @click="onRadioClick(item, option)"
                                    >
                                      <v-card-text class="pa-2">
                                        <div class="option-value-row">
                                          <div class="option-value-row-control">
                                            <v-radio
                                              :value="option.Id"
                                              class="mt-0 pt-0"
                                              color="gfp-adv-common-product-dialog-body-option-control"
                                              hide-details
                                              placeholder=""
                                            >
                                              <span
                                                slot="label"
                                                :class="{'font-weight-bold': option.Selected}"
                                                class="gfp-adv-common-product-dialog-body-option-text--text"
                                                style="font-size: 14px; line-height: 17px;"
                                                v-html="option.Lang[$i18n.locale].Name"
                                              />
                                            </v-radio>
                                          </div>
                                        </div>
                                      </v-card-text>
                                    </v-card>
                                  </v-flex>
                                </template>
                              </v-radio-group>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </template>

                  <v-flex
                    v-if="!isCatalogOnly"
                    xs12
                  >
                    <v-textarea
                      v-model="selectedMenuItem.Comment"
                      color="gfp-adv-common-product-dialog-body-comments"
                      class="mx-2"
                      rows="1"
                      auto-grow
                      :hint="$t('Form.MenuComments.Hint')"
                      :label="$t('Form.MenuComments.Label')"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </template>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions
        v-if="!isCatalogOnly"
        class="pa-2 gfp-adv-common-product-dialog-actions-background"
      >
        <template v-if="dataStore.cart">
          <v-alert
            :dismissible="false"
            :value="dataStore.cart.IsClosed"
            class="ma-0 caption white text-xs-center"
            outline
            style="width: 100%; position:sticky;"
            type="error"
          >
            <span
              style="width:100%;"
              v-html="$t('Common.Error.StoreClosed')"
            />
          </v-alert>

          <v-layout
            v-if="!dataStore.cart.IsClosed"
            row
            wrap
          >
            <v-flex
              v-if="!!menuItemSelectedOptionsText && showSelectedMenuOptions"
              xs12
            >
              <div class="caption px-1 pt-1 pb-2 gfp-adv-common-product-dialog-actions-text--text">
                <strong>{{ $t('MenuOptionsDialog.SelectedOptions') }}:</strong> {{ menuItemSelectedOptionsText }}
              </div>
            </v-flex>

            <v-flex
              class="text-xs-left pa-1 no-wrap"
              xs5
            >
              <template v-if="selectedMenuItem.UnitId > 1 && !menuItemHasUnitQuantityOptions">
                <span class="hidden-xs-only pr-2 gfp-adv-common-product-dialog-actions-quantity-title--text">
                  {{ $t('Buttons.Quantity').toUpperCase() | sanitizeDiacritics }}
                </span>

                <v-btn
                  :disabled="unitQuantity - parseInt(selectedMenuItem.DefaultUnitQuantity) <= parseInt(selectedMenuItem.MinimumQuantity || selectedMenuItem.DefaultUnitQuantity)"
                  class="gfp-adv-common-product-dialog-actions-quantity-button--text elevation-0"
                  outline
                  style="min-width: 40px; max-width: 40px;"
                  @click="decrementUnitQuantity"
                >
                  -
                </v-btn>

                <v-btn
                  class="gfp-adv-common-product-dialog-actions-quantity-button--text elevation-0 ml-1 text-none"
                  disabled
                  outline
                  style="min-width: 80px; max-width: 120px; background-color: white !important;"
                >
                  {{ formatUnitQuantity($i18n.locale, selectedMenuItem.UnitQuantity, getMeasurementUnitById(selectedMenuItem.UnitId)) }}
                </v-btn>

                <v-btn
                  class="gfp-adv-common-product-dialog-actions-quantity-button--text elevation-0 ml-1"
                  outline
                  style="min-width: 40px; max-width: 40px;"
                  @click="incrementUnitQuantity"
                >
                  +
                </v-btn>
              </template>

              <template v-else-if="selectedMenuItem.UnitId === 1">
                <span class="hidden-xs-only pr-2 gfp-adv-common-product-dialog-actions-quantity-title--text">
                  {{ $t('Buttons.Quantity').toUpperCase() | sanitizeDiacritics }}
                </span>

                <v-btn
                  :disabled="quantity<=parseInt(selectedMenuItem.MinimumQuantity)"
                  class="gfp-adv-common-product-dialog-actions-quantity-button--text elevation-0"
                  outline
                  style="min-width: 40px; max-width: 40px;"
                  @click="decrementQuantity"
                >
                  -
                </v-btn>

                <v-btn
                  class="gfp-adv-common-product-dialog-actions-quantity-button--text elevation-0 ml-1"
                  disabled
                  outline
                  style="min-width: 50px; max-width: 120px; background-color: white !important;"
                >
                  {{ selectedMenuItem.Quantity }}
                </v-btn>

                <v-btn
                  :disabled="quantity>=parseInt(selectedMenuItem.StockQuantity) && selectedMenuItem.SubtractStock"
                  class="gfp-adv-common-product-dialog-actions-quantity-button--text elevation-0 ml-1"
                  outline
                  style="min-width: 40px; max-width: 40px;"
                  @click="incrementQuantity"
                >
                  +
                </v-btn>
              </template>
            </v-flex>

            <v-flex
              class="text-xs-right pa-1"
              xs7
            >
              <v-btn
                v-if="menuOptionsDialogEditMode"
                :disabled="submitButton"
                :loading="submitButton"
                class="gfp-adv-common-product-dialog-actions-button-background gfp-adv-common-product-dialog-actions-button-title--text pa-2"
                style="min-width: 155px"
                flat
                @click="onSaveButtonClick"
              >
                <div class="mr-2 flex text-xs-left">
                  {{ $t('Buttons.Update') | sanitizeDiacritics }}
                </div>
                <div class="flex text-xs-right">
                  {{ menuItemTotal | currency }}
                </div>
              </v-btn>

              <v-btn
                v-else
                :disabled="submitButton"
                :loading="submitButton"
                class="gfp-adv-common-product-dialog-actions-button-background gfp-adv-common-product-dialog-actions-button-title--text pa-2"
                style="min-width: 155px;"
                flat
                @click="onSaveButtonClick"
              >
                <div class="mr-2 flex text-xs-left">
                  {{ $t('Buttons.Add') | sanitizeDiacritics }}
                </div>
                <div class="flex text-xs-right">
                  {{ menuItemTotal | currency }}
                </div>
              </v-btn>
            </v-flex>
          </v-layout>
        </template>
      </v-card-actions>

      <v-card-actions
        v-else-if="isCatalogOnly && hasLandingPage && hasMultipleLocations"
        class="pa-2"
      >
        <v-spacer />

        <v-btn
          :href="landingPage"
          class="gfp-adv-common-product-dialog-actions-button-background gfp-adv-common-product-dialog-actions-button-title--text"
          flat
        >
          {{ $t('CategoriesBlock.Title') | sanitizeDiacritics }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Watchable              from '@/mixins/watchable'
import AppData                from '@/mixins/appdata'
import MenuItemCommon         from '@/mixins/menu/menuItemCommon'
import AnalyticsCommon        from '@/mixins/analytics/analyticsCommon'
import DataEventEnum          from '@/lib/services/enum/DataEventEnum'
import DataEventService       from '@/lib/services/event/DataEventService'
import { getContrastColor }   from '@/lib/color'
import { formatUnitQuantity } from '@/lib/currency/currency'

export default {
  components: {},
  mixins    : [AppData, Watchable, MenuItemCommon, AnalyticsCommon],
  props     : {
    selectedMenuItem: {
      type    : Object,
      required: false
    },
    menuOptionsDialogEditMode: {
      type    : Boolean,
      required: true
    },
    menuOptionsDialogVisible: {
      type    : Boolean,
      required: true
    }
  },
  data () {
    return {
      toolbarVisible: false,
      toolbarColor  : 'gfp-adv-common-product-dialog-toolbar-background',
      toolbarHeight : 110
    }
  },
  computed: {
    showSelectedMenuOptions () {
      return this?.appConfig?.LOCATION_DATA?.ShowSelectedMenuOptions || false
    },

    menuItemSelectedOptionsText () {
      return this.selectedMenuItem.Options
        ?.map(option => option.OptionValues
          ?.filter(optionValue => optionValue.Selected)
          ?.map(optionValue => optionValue?.Lang[this.$i18n.locale]?.Name || optionValue?.Name)?.join(', '))
        ?.filter(item => item.length)?.join(', ')
    },

    DialogOptionsContentHasOverflow () {
      if (this.$refs.dialogOptionsContent) {
        if (this.$refs.dialogOptionsContent.scrollHeight > this.$refs.dialogOptionsContent.innerHeight) {
          return true
        } else {
          return true
        }
      }
      return false
    },

    isVisible: {
      get () {
        return this.menuOptionsDialogVisible
      },
      set (val) {
        this.$emit('update:menuOptionsDialogVisible', val)
      }
    }
  },
  watch: {
    isVisible (newVal, oldVal) {
      if (newVal) {
        this.submitButton = false
        this.initMenuItem()
        this.calculateToolbarHeight()
        this.menuItemViewEventGA()
      } else {
        this.$refs.dialogOptionsContent.scrollTop = 0
        this.$set(this.selectedMenuItem, 'OptionsCopy', null)
        this.$set(this.selectedMenuItem, 'Options', null)
        this.dataStore.loadingMenuItem = false
      }
    }
  },
  methods: {
    formatUnitQuantity,
    getContrastColor,
    visibilityChanged (visible, entry) {
      this.toolbarVisible = !visible
      this.calculateToolbarHeight()
    },

    menuItemViewEventGA () {
      const item = this.menuItemToGAItem(this.selectedMenuItem)
      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.VIEW_ITEM,
        Payload: {
          items   : [item],
          value   : (item.price - item.discount) * item.quantity,
          currency: item.currency
        }
      })
    },

    onSaveButtonClick () {
      if (!this.isRequiredOptionsValid()) return

      this.submitButton = true
      if (!this.isVisible) return
      this.$bus.$emit('save:menuOptionsItem', this.selectedMenuItem)
    },

    onCancelButtonClick () {
      this.isVisible = false
    },

    calculateToolbarHeight () {
      this.$nextTick(() => {
        const toolbarTitle = this.$refs.toolbarTitle
        if (toolbarTitle) {
          this.toolbarHeight = this.selectedMenuItem.Image && this.UseMenuLargeImage && this.$vuetify.breakpoint.smAndUp ? toolbarTitle.clientHeight + 70 : toolbarTitle.clientHeight < 110 && this.selectedMenuItem.Image && !this.UseMenuLargeImage && this.$vuetify.breakpoint.smAndUp ? 120 : toolbarTitle.clientHeight + 25
        }
      })
    },

    onResize () {
      this.calculateToolbarHeight()
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__title {
  white-space : normal !important;
}

/deep/ .menu-image > .v-toolbar__content {
  padding-left : 12px !important;
}

/deep/ .v-input--radio-group--row .v-input__control {
  width : 100%;
}

/deep/ .v-input--checkbox .v-icon:not(.gfp-adv-common-product-dialog-body-option-control--text), /deep/ .v-input--radio-group .v-icon:not(.gfp-adv-common-product-dialog-body-option-control--text) {
  color : rgba(0, 0, 0, 0.15) !important;
}

.separator-title {
  position   : relative;
  z-index    : 1;
  overflow   : hidden;
  text-align : center;
}

.separator-title:before, .separator-title:after {
  position         : absolute;
  top              : 51%;
  overflow         : hidden;
  width            : 48%;
  height           : 1px;
  content          : '\a0';
  background-color : #cccccc;
  margin-left      : 2%;
}

.separator-title:before {
  margin-left : -50%;
  text-align  : right;
}

.option-value-row {
  display         : flex;
  width           : 100%;
  justify-content : space-between;
  align-items     : center;
}

.option-value-row-control {
  overflow      : hidden;
  min-width     : 0;
  text-overflow : ellipsis;
  margin-right  : 6px;
}

.option-value-row-price {
  text-align : right;
}

.menu-tag {
  margin        : 0;
  margin-right  : 4px;
  height        : 18px !important;
  border-radius : 3px;
}

/deep/ .menu-tag .v-chip__content {
  padding : 2px;
}

</style>
